import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Selectors
import { getCityConfig } from '../../selectors';

class PrivacyPolicy extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { cityConfig, isPublic } = this.props;

    const supportEmail = cityConfig.support_email;

    return (
      <React.Fragment>
        <h4 className={isPublic ? 'u-mb2' : ''}>Effective Date: 9/6/2024</h4>

        <p>This Privacy Policy describes how Curbside Provisions, (“Company”, “we”, “us”, or “our”) collects, uses, and shares information about you when you interact with our websites, mobile applications, and other online products and services that link to this Privacy Policy (collectively, the “Services”). This policy also explains your privacy rights under applicable law and how you can exercise them.</p>
        <p>We may update this Privacy Policy periodically. If we make any changes, we will notify you by revising the date at the top of this page and, in some cases, we may provide you with additional notice (such as by posting a notice on our homepage or sending you a notification). We encourage you to review this Privacy Policy whenever you access our Services to stay informed about our data practices.</p>

        <hr/>
        <h3>Table of Contents</h3>
        <ul>
          <li>Personal Information We Collect</li>
          <li>How We Use Your Personal Information</li>
          <li>How We Share Your Personal Information</li>
          <li>Use and Transfer of Google User Data</li>
          <li>Cookies and Similar Technologies</li>
          <li>Your Choices</li>
          <li>Data Security</li>
          <li>How to Request Data Deletion</li>
          <li>International Data Transfers</li>
          <li>State Law Privacy Rights (including California)</li>
          <li>Children’s Privacy</li>
          <li>Changes to This Privacy Policy</li>
          <li>How to Contact Us</li>
        </ul>

        <hr/>
        <h3>Personal Information We Collect</h3>
        <h4>Information You Provide to Us</h4>
        <p>When you use our Services, we may collect the following types of personal information:</p>
        <ul>
          <li><strong>Contact Information:</strong> Your first and last name, email address, mailing address, and phone number.</li>
          <li><strong>Account Information:</strong> Username, password, and preferences you set to establish an account with us.</li>
          <li><strong>Transaction Information:</strong> Information related to purchases or services, including payment information (such as credit or debit card details).</li>
          <li><strong>Content You Upload:</strong> Information you choose to upload, such as text, images, videos, and the metadata associated with these files.</li>
          <li><strong>Demographic Information:</strong> Age, city, state, and postal code.</li>
          <li><strong>Feedback:</strong> Information you provide when you communicate with us (e.g., via social media, customer support, or surveys).</li>
        </ul>
        <h4>Information We Collect Automatically</h4>
        <p>When you access or use the Services, we automatically collect information such as:</p>
        <ul>
          <li><strong>Log Information:</strong> Information about your use of the Services, such as browser type, access times, pages viewed, and your IP address.</li>
          <li><strong>Device Information:</strong> Information about the device used to access our Services, such as the operating system, browser type, and device identifiers.</li>
          <li><strong>Cookies and Similar Technologies:</strong> Information collected via cookies, web beacons, or similar technologies to track activity and improve our Services.</li>
          <li><strong>Geolocation Data:</strong> Information about your location if you provide it to us or allow our Services to access it.</li>
        </ul>
        <h4>Data From Other Sources</h4>
        <p>We may obtain information from other sources, such as:</p>
        <ul>
          <li><strong>Social Media:</strong> If you log in through or connect your social media account to our Services, we may collect information from that platform, such as your username and profile information.</li>
          <li><strong>Business Partners:</strong> Information about vendors or third-party providers may be collected to provide services.</li>
        </ul>

        <hr/>
        <h3>How We Use Your Personal Information</h3>
        <p>We may use your personal information for the following purposes:</p>
        <ul>
          <li><strong>To Operate and Improve the Services:</strong> Including providing and maintaining your account, processing transactions, and responding to your inquiries.</li>
          <li><strong>To Communicate with You:</strong> Including sending transactional emails, promotional content, and notifications about updates or changes to the Services.</li>
          <li><strong>For Research and Development:</strong> Analyzing usage and trends to improve the functionality of our Services and develop new products.</li>
          <li><strong>For Compliance and Safety:</strong> Ensuring compliance with applicable laws, protecting the rights and safety of our users, and detecting fraud or other harmful activities.</li>
          <li><strong>To Create Aggregated or De-Identified Data:</strong> Using anonymized data for lawful business purposes such as improving the Services and conducting research.</li>
        </ul>

        <hr/>
        <h3>How We Share Your Personal Information</h3>
        <p>We may share your information with the following parties:</p>
        <ul>
          <li><strong>Service Providers:</strong> Third-party vendors that perform functions on our behalf, such as hosting, payment processing, analytics, or customer support.</li>
          <li><strong>Affiliates:</strong> Entities related to Curbside Provisions for purposes consistent with this Privacy Policy.</li>
          <li><strong>Business Partners:</strong> If you engage with third-party vendors through our Services, we may share your information with them to fulfill your requests.</li>
          <li><strong>For Legal Compliance:</strong> We may share information when required by law or to respond to legal requests, subpoenas, or government investigations.</li>
          <li><strong>With Your Consent:</strong> In other situations where you provide explicit consent for the sharing of your data.</li>
        </ul>

        <hr/>
        <h3>Use and Transfer of Google User Data</h3>
        <p>We comply with Google’s policies regarding the use and transfer of Google user data. <strong>We only use Google user data to provide or improve user-facing features of our application.</strong> Specifically, <strong>we do not use Google user data for:</strong></p>
        <ul>
          <li>Targeted advertising</li>
          <li>Selling data to brokers or information resellers</li>
          <li>Determining credit-worthiness or lending purposes</li>
          <li>Personalized, retargeted, or interest-based advertising</li>
          <li>Creating databases or training AI models</li>
        </ul>
        <p><strong>Google user data is not transferred to third parties</strong> unless it is necessary for providing or improving your experience within our application. If we make any changes to our use or transfer of Google user data, we will notify you and update this policy accordingly.</p>

        <hr/>
        <h3>Cookies and Similar Technologies</h3>
        <p>We use cookies, web beacons, and similar tracking technologies to collect information about your usage of the Services and improve your experience. These technologies help us remember your preferences, track the effectiveness of our campaigns, and analyze site performance.</p>
        <p>You can manage your cookie preferences through your browser settings. However, please note that disabling cookies may affect the functionality of the Services.</p>
        <p>For more details about cookies and how we use them, refer to our Cookie Policy.</p>

        <hr/>
        <h3>Your Choices</h3>
        <p>You have the following choices regarding your personal information:</p>
        <ul>
          <li><strong>Access or Update Information:</strong> You can access or update your account information by logging into your account or contacting us at <a href={"mailto:" + supportEmail}><span>{supportEmail}</span></a>.</li>
          <li><strong>Opt-Out of Marketing Communications:</strong> You may opt out of promotional emails by following the unsubscribe link in those emails. You will continue to receive transactional or non-promotional emails.</li>
          <li><strong>Cookies and Tracking:</strong> Most web browsers allow you to reject cookies or notify you when cookies are being used. You can also use tools like privacy-focused browsers to limit tracking.</li>
          <li><strong>Location Data:</strong> You can manage location permissions through your device settings.</li>
          <li><strong>Do Not Track:</strong> We do not respond to Do Not Track signals sent by your browser.</li>
        </ul>

        <hr/>
        <h3>Data Security</h3>
        <p>We take reasonable measures to protect your personal information from unauthorized access, loss, misuse, or alteration. However, no system is completely secure, and we cannot guarantee the security of your data.</p>

        <hr/>
        <h3>How to Request Data Deletion</h3>

        <p>You have the right to request the deletion of your personal information that we have collected. To exercise this right:</p>
        <ol>
          <li><strong>Submit a Data Deletion Request:</strong> You can send a request to delete your data by contacting us via email at support@seattlefoodtruck.com.</li>
          <li><strong>Information Needed:</strong> Include the following in your request:
            <ul>
              <li>Your name</li>
              <li>The email address associated with your account</li>
              <li>A description of the specific data you would like deleted</li>
            </ul>
          </li>
          <li><strong>Verification of Identity:</strong> To ensure the security of your data, we may ask for additional information to verify your identity before processing your request.</li>
          <li><strong>Processing Time:</strong> We will acknowledge your request within 10 business days and aim to complete the data deletion process within 30 business days of verifying your identity, unless a longer timeframe is required by applicable law.</li>
        </ol>
        <p>Please note that certain data may need to be retained to comply with legal obligations, resolve disputes, enforce agreements, or fulfill other legitimate business purposes.</p>
        <p>For further assistance, you may contact us:<br/>
        <strong>Email:</strong> <a href={"mailto:" + supportEmail}><span>{supportEmail}</span></a><br/>
        <strong>Mail:</strong> Curbside Provisions, 600 1st Ave Ste 330 #45647, Seattle, WA, 98104-2246</p>

        <hr/>
        <h3>International Data Transfers</h3>
        <p>If you access our Services from outside the United States, your information may be transferred to and processed in the U.S. or other countries where our servers are located. We comply with applicable data protection laws when transferring data internationally.</p>

        <hr/>
        <h3>State Law Privacy Rights</h3>
        <h4>California Privacy Rights (CCPA)</h4>
        <p>If you are a California resident, the California Consumer Privacy Act (CCPA) provides you with certain rights regarding your personal information, including:</p>
        <ul>
          <li><strong>Right to Know:</strong> You can request information about the personal information we have collected about you over the past 12 months.</li>
          <li><strong>Right to Delete:</strong> You may request that we delete the personal information we have collected from you.</li>
          <li><strong>Right to Opt-Out of Sale:</strong> We do not sell your personal information. However, if we do in the future, you will have the right to opt-out.</li>
          <li><strong>Right to Non-Discrimination:</strong> You will not face discrimination for exercising your privacy rights.</li>
        </ul>
        <p>To exercise any of these rights, contact us at <a href={"mailto:" + supportEmail}><span>{supportEmail}</span></a>. We may need to verify your identity to process your request.</p>
        <p>For more information about your California privacy rights, please visit our CCPA Policy.</p>

        <hr/>
        <h3>Children’s Privacy</h3>
        <p>Our Services are not directed to children under 16, and we do not knowingly collect personal information from anyone under 16. If we learn that we have collected personal information from a child under 16, we will delete that information as soon as possible. If you believe we have collected such information, please contact us at <a href={"mailto:" + supportEmail}><span>{supportEmail}</span></a>.</p>

        <hr/>
        <h3>Changes to This Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time. If we make material changes, we will notify you by posting the updated policy on our website and updating the “Effective Date” at the top. For significant changes, we may also notify you via email or another prominent method. Your continued use of the Services indicates your acceptance of any revised Privacy Policy.</p>

        <hr/>
        <h3>How to Contact Us</h3>
        <p>If you have any questions about this Privacy Policy or wish to exercise your privacy rights, please contact us:</p>
        <p>
          Curbside Provisions<br/>
          600 1st Ave Ste 330 #45647<br/>
          Seattle, WA 98104<br/>
          Email: <a href={"mailto:" + supportEmail}><span>{supportEmail}</span></a>
        </p>
      </React.Fragment>
    );
  }
}

PrivacyPolicy.propTypes = {
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
