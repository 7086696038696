import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Meta from '../../shared/Meta';
import PrivacyPolicy from '../../shared/PrivacyPolicy';

// Actions
import { clearServerRenderedPath } from '../../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../../selectors';

// Misc
import IsoMorphUtils from '../../../modules/isoMorphUtils';
import styles from '../../../../stylesheets/public/layouts/AboutPage.module.postcss';

class Privacy extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  render() {
    const { location, cityConfig } = this.props;

    let contactAddress = "5425 Ballard Ave NW, Suite 4, Seattle WA 98107";
    if (cityConfig.city_name !== "Seattle") {
      contactAddress = "113 Cherry Street #45647, Seattle WA 98104";
    }

    return (
      <div>
        <Meta
          title={`Privacy Policy - ${cityConfig.site_title}`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Privacy Policy",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />
        <h2 className={styles.PrivacyPage_header}>Curbside Provisions Privacy Policy</h2>

        <div className={styles.AboutPage_body}>
          <PrivacyPolicy isPublic />
        </div>
      </div>
    );
  }
}

Privacy.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
