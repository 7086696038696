import React, { Component } from 'react';

// Components
import PrivacyPolicy from '../../shared/PrivacyPolicy';

class Privacy extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="AdminWrapper">
        <h2 className='u-mb1'>Curbside Provisions Privacy Policy</h2>
        <PrivacyPolicy/>
      </div>
    );
  }
}

export default Privacy;
